export const GetDraftsCount = /* GraphQL */ `
  query getDraftsCount(
    $filter: CatalogProductDraftCollectionResolver_GetDraftCollection_FilterInputType
    $paginate: PaginatorInputArgs
    $sort: CatalogProductDraftCollectionResolver_GetDraftCollection_SortingInputType
  ) {
    draftCollection(filter: $filter, paginate: $paginate, sort: $sort) {
      items {
        portfolioItem {
          isCustomized
        }
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`
export const GetAllDrafts = /* GraphQL */ `
  query getAllDrafts(
    $filter: CatalogProductDraftCollectionResolver_GetDraftCollection_FilterInputType
    $paginate: PaginatorInputArgs
    $sort: CatalogProductDraftCollectionResolver_GetDraftCollection_SortingInputType
  ) {
    draftCollection(filter: $filter, paginate: $paginate, sort: $sort) {
      items {
        id
        draftGroupId
        image
        price
        quantity
        userId
        updatedAt
        portfolioItem {
          id
          memberId
          notes
          tenant
          customizer {
            stateId
            urlPrint
            urlProofs {
              position
              url
            }
            userId
          }
          isCustomized
        }
        variation {
          id
          catalogProduct {
            externalId
            catalogProductTypeCode
            name
            slug
            catalogProductType {
              name
            }
          }
          catalogProductSuiteCode
          images {
            url
            order
            role
          }
          optionValues {
            name
            optionId
            valueId
          }
          isSample
        }
      }
      pagination {
        page
        perPage
        total
      }
    }
  }
`
