import { z } from 'zod'

export const Pagination = z.object({
  page: z.number(),
  perPage: z.number(),
  total: z.number(),
})

export const urlProofs = z.object({
  position: z.number(),
  url: z.string(),
})

export const Customizer = z.object({
  stateId: z.string(),
  urlPrint: z.string(),
  urlProofs: z.array(urlProofs),
  userId: z.string(),
})

export const PortfolioItem = z.object({
  id: z.string(),
  memberId: z.string().nullish(),
  notes: z.string().nullish(),
  tenant: z.string(),
  customizer: Customizer.nullish(),
  isCustomized: z.boolean().nullish(),
})

export const CatalogProduct = z.object({
  externalId: z.string(),
  catalogProductTypeCode: z.string(),
  name: z.string(),
  slug: z.string(),
  catalogProductType: z.object({
    name: z.string(),
  }),
})

export const VariationImage = z.object({
  url: z.string(),
  order: z.number(),
  role: z.string(),
})

export const OptionValues = z.object({
  name: z.string(),
  optionId: z.string(),
  valueId: z.string(),
})

export const Variation = z.object({
  id: z.string(),
  catalogProduct: CatalogProduct,
  images: z.array(VariationImage).nullish(),
  optionValues: z.array(OptionValues),
  isSample: z.boolean().nullish(),
  catalogProductSuiteCode: z.string().nullish(),
})

export const Draft = z.object({
  id: z.string(),
  draftGroupId: z.string(),
  image: z.string(),
  updatedAt: z.string().nullish(),
  portfolioItem: PortfolioItem,
  variation: Variation,
  price: z.number(),
  quantity: z.number(),
  userId: z.string(),
})

const PortfolioItemIsCustomized = PortfolioItem.pick({ isCustomized: true })

export const DraftCustomized = z.object({
  portfolioItem: PortfolioItemIsCustomized,
})

export const DraftsCount = z.object({
  items: z.array(DraftCustomized),
  pagination: Pagination,
})

export const DraftCollection = z.object({
  items: z.array(Draft),
  pagination: Pagination,
})

export type VariationImage = z.infer<typeof VariationImage>

export type OptionValues = z.infer<typeof OptionValues>

export type CatalogProduct = z.infer<typeof CatalogProduct>

export type Customizer = z.infer<typeof Customizer>

export type PortfolioItem = z.infer<typeof PortfolioItem>

export type DraftCollection = z.infer<typeof DraftCollection>

export type Pagination = z.infer<typeof Pagination>

export type Variation = z.infer<typeof Variation>

export type Draft = z.infer<typeof Draft>

export type DraftsCount = z.infer<typeof DraftsCount>
