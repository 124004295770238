import { QueryDraftCollectionArgs } from '@/domains/core/storefront/generated'
import { IncomingHttpHeaders } from 'node:http'
import { storefrontAPIFetch } from '../core/storefront/legacy'
import { GetAllDrafts, GetDraftsCount } from './draft-graphql'
import { DraftCollection, DraftsCount } from './draft.schema'
import Honeybadger from '@/modules/core/services/honeybadger.service'

export async function getDraftsCount(headers?: IncomingHttpHeaders) {
  try {
    const variables: QueryDraftCollectionArgs = {}
    const response = await storefrontAPIFetch<string, QueryDraftCollectionArgs>(GetDraftsCount, variables, headers)

    const drafts = DraftsCount.safeParse(response.draftCollection)

    if (!drafts.success) {
      throw new Error(`Invalid drafts count`, drafts.error)
    }

    return drafts.data
  } catch (error: any) {
    Honeybadger.notify(error, {
      name: 'getDraftsCount @ domains/draft/draft.query.ts',
      message: error?.message,
    })

    throw new Error(`Error in draft.query.ts: ${error}`)
  }
}

export async function getAllDrafts(variables: QueryDraftCollectionArgs, headers?: IncomingHttpHeaders) {
  try {
    const response = await storefrontAPIFetch<string, QueryDraftCollectionArgs>(GetAllDrafts, variables, headers)

    const drafts = DraftCollection.safeParse(response.draftCollection)

    if (!drafts.success) {
      throw new Error(`Invalid drafts:`, drafts.error)
    }

    return drafts.data
  } catch (error: any) {
    Honeybadger.notify(error, {
      name: 'getAllDrafts @ domains/draft/draft.query.ts',
      message: error?.message,
    })
    throw new Error(`Error in draft.query.ts: ${error}`)
  }
}
