import { storefrontAPIFetch } from '@/domains/core/storefront/legacy'
import {
  AddItemToCartInput,
  AddOrderedItemToCartMutationVariables,
  AddOrderedSampleItemToCartMutation,
  AddOrderedSampleItemToCartMutationVariables,
  CartDetails,
  CartDetailsItem,
} from '@/generated/graphql/graphql'
import { AddOrderedItemToCart, AddOrderedSampleItemToCart } from '@/graphql/cart'
import { AddItemToCart, CartItemCount } from '@/graphql/commerceCart'
import { OrderDetailsOrderItem } from '@/pages/your-orders/[id]'
import Honeybadger from '@honeybadger-io/js'
import { IncomingHttpHeaders } from 'http'

function addToCart(input: AddItemToCartInput) {
  return storefrontAPIFetch(AddItemToCart, { input: input })
}

export enum ReorderReasonCode {
  BuyMore = 4,
}

export async function addPersonalizedSampleToCart(sampleVariationId: string): Promise<Record<string, string>> {
  const { cart } = await addToCart({
    isCustomized: true,
    quantity: 1,
    variationId: sampleVariationId,
  })

  return {
    cartId: cart.addItem.cart.storeCartId,
    portfolioItemId: cart.addItem.item?.portfolioItem?.id,
  }
}

export const reportItemIneligibleForReorderError = (originalOrderId: number, item: OrderDetailsOrderItem) => {
  Honeybadger.notify('Attempted reorder of ineligible order item', {
    context: {
      orderId: originalOrderId,
      orderItemId: item.id,
      portfolioItemId: item.portfolioItem?.id,
      variationId: item.variation.id,
      reorderVariationId: item.reorderVariationId,
      reorderEligible: item.reorderEligible,
    },
  })
}

export async function addOrderedItemToCart(
  originalOrderId: number,
  item: OrderDetailsOrderItem
): Promise<{ cart: Partial<CartDetails>; item: Partial<CartDetailsItem> }> {
  if (!item.reorderEligible || !item.reorderVariationId) {
    reportItemIneligibleForReorderError(originalOrderId, item)
    throw new Error('Item is not eligible for reorder')
  }

  const variables: AddOrderedItemToCartMutationVariables = {
    input: {
      quantity: item.variation.productCode === 'GBK' ? 1 : 15,
      variationId: item.reorderVariationId,
      isCustomized: Boolean(item.portfolioItem),
      reorderAttributes: {
        originalLineItemId: item.id.toString(),
        originalOrderId,
        reorderReasonCode: ReorderReasonCode.BuyMore,
      },
    },
  }

  if (item.portfolioItem) {
    variables.input.portfolioItem = {
      customizer: item.portfolioItem?.customizer,
      memberId: item.portfolioItem?.memberId,
      fileType: item.portfolioItem?.fileType,
      selectableOptionValues: item.portfolioItem?.selectableOptionValues,
      templateId: item.portfolioItem?.templateId,
    }
  }

  try {
    const response = await storefrontAPIFetch<string, AddOrderedItemToCartMutationVariables>(
      AddOrderedItemToCart,
      variables
    )
    return {
      item: response.cart?.addItem?.item,
      cart: response.cart?.addItem?.cart,
    }
  } catch (error: any) {
    Honeybadger.notify('Adding ordered item to cart failed', {
      message: error.message,
      context: {
        originalOrderId,
        originalOrderItemId: item.id,
        mutationInput: variables.input,
      },
    })

    throw error
  }
}

export async function addReorderedSampleItemToCart(
  originalOrderId: number,
  item: OrderDetailsOrderItem
): Promise<string> {
  if (!item.reorderEligible || !item.reorderVariationId) {
    reportItemIneligibleForReorderError(originalOrderId, item)
    throw new Error('Item is not eligible for reorder')
  }

  const variables: AddOrderedSampleItemToCartMutationVariables = {
    input: {
      quantity: 100,
      variationId: item.reorderVariationId,
      isCustomized: true,
    },
  }

  if (item.portfolioItem) {
    variables.input.portfolioItem = {
      memberId: item.portfolioItem?.memberId,
      customizer: item.portfolioItem?.customizer,
      fileType: item.portfolioItem?.fileType,
      selectableOptionValues: item.portfolioItem?.selectableOptionValues,
      templateId: item.portfolioItem?.templateId,
    }
  }

  try {
    const response = (await storefrontAPIFetch<string, AddOrderedSampleItemToCartMutationVariables>(
      AddOrderedSampleItemToCart,
      variables
    )) as AddOrderedSampleItemToCartMutation

    const portfolioItemId = response.cart.addItem?.item?.portfolioItem?.id

    if (!portfolioItemId) {
      throw new Error('Ordered sample item added to cart without a portfolio item')
    }

    return portfolioItemId
  } catch (error: any) {
    Honeybadger.notify('Adding ordered sample item to cart failed', {
      message: error.message,
      context: {
        originalOrderId,
        originalOrderItemId: item.id,
        mutationInput: variables.input,
      },
    })

    throw error
  }
}

export async function getCartItemCount(headers?: IncomingHttpHeaders): Promise<number> {
  const response = await storefrontAPIFetch<string, undefined>(CartItemCount, undefined, headers)
  return response?.cart?.itemCount || 0
}
